import { useEffect, useState } from "react";
import FormCheckModem from "./modem/FormCheckModem";
import { checkProvisioningFromPartnerInfra } from "../../api/projects";
import { useSearchPartnerSplitterListHooks } from "../../utils/infrastructure/PartnerSplitterUtils";
import useDebounce from "../../utils/useDebounceHooks";
import InputMask from "../ui/InputMask";

export default function IconvestFormSetupModem({ registration, onSubmit, submitLoading }) {
  let [form, setForm] = useState({
    pppoe_username: "",
    pppoe_password: "",
    framed_pool: "",
    olt: "",
    vlan: "",
    odp: "",
    odc: "",
    inventory_modem_id: "",
    serial_number_gpon: "",
    latitude: "",
    longitude: "",
    partner_odp_splitter_id: "",
    partner_odp_splitter_port: "",
    mac_address_ont: "",
  });

  useEffect(() => {
    setForm({
      pppoe_username: registration.inventory_modem_pair?.modem?.pppoe_username || "",
      pppoe_password: registration.inventory_modem_pair?.modem?.pppoe_password || "",
      framed_pool: registration.inventory_modem_pair?.modem?.framed_pool || "",
      olt: registration.inventory_modem_pair?.modem?.olt || "",
      vlan: registration.inventory_modem_pair?.modem?.vlan || "",
      odp: registration.inventory_modem_pair?.modem?.odp || "",
      odc: registration.inventory_modem_pair?.modem?.odc || "",
      inventory_modem_id: registration.inventory_modem_pair?.modem?.id,
      serial_number_gpon: registration.inventory_modem_pair?.modem?.serial_number_gpon,
      latitude: registration.inventory_modem_pair?.modem?.latitude
        ? registration.inventory_modem_pair?.modem?.latitude
        : registration.bast_survey?.latitude || "",
      longitude: registration.inventory_modem_pair?.modem?.longitude
        ? registration.inventory_modem_pair?.modem?.longitude
        : registration.bast_survey?.longitude || "",
      partner_odp_splitter_id:
        registration.inventory_modem_pair?.modem?.partner_odp_splitter_id || "",
      partner_odp_splitter_port:
        registration.inventory_modem_pair?.modem?.partner_odp_splitter_port || "",
      mac_address_ont: registration.inventory_modem_pair?.modem?.mac_address || "",
    });
  }, [registration]);

  const onSubmitData = (event) => {
    event.preventDefault();

    onSubmit(form);
  };
  return (
    <>
      {registration.partner_provisioning.length !== 0 ? (
        <div className="card mb-4">
          <div className="card-body">
            <div className="font-weight-bold mb-2">Provisioning Berlangsung</div>
            <table className="table table-sm table-borderless">
              <tbody>
                {registration.partner_provisioning.map((provisioning) => (
                  <tr key={provisioning.id}>
                    <td>{provisioning.partner_provisioning_id}</td>
                    <td>{provisioning.type}</td>
                    <td>
                      <CheckProvisioningBtn id={provisioning.id} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <ol className="pl-3"></ol>
          </div>
        </div>
      ) : null}
      <form onSubmit={(event) => onSubmitData(event)}>
        <FormCheckModem
          serial={registration.inventory_modem_pair?.modem?.serial_number_modem || ""}
          value={form.inventory_modem_id}
          onChangeModemSuccess={({ id, ...rest }) =>
            setForm({ ...form, inventory_modem_id: id, ...rest })
          }
        />
        <div className="form-group">
          <label>SN GPON Modem</label>
          <input type="text" className="form-control" value={form.serial_number_gpon} disabled />
        </div>
        <div className="form-group">
          <label>Mac Address ONT/Modem</label>
          {/* <input
            type="text"
            className="form-control"
            value={form.mac_address_ont}
            onChange={(event) => setForm({ ...form, mac_address_ont: event.target.value })}
          /> */}
          <InputMask
            value={form.mac_address_ont}
            onChange={(value) => setForm({ ...form, mac_address_ont: value })}
            mask="**-**-**-**-**-**"
            placeholder="XX-XX-XX-XX-XX-XX"
          />
        </div>
        <div className="form-group">
          <label>PPPoE Username</label>
          <input
            type="text"
            className="form-control"
            value={form.pppoe_username}
            onChange={(event) => setForm({ ...form, pppoe_username: event.target.value })}
            disabled
          />
        </div>
        <div className="form-group">
          <label>PPPoE Password</label>
          <input
            type="text"
            className="form-control"
            value={form.pppoe_password}
            onChange={(event) => setForm({ ...form, pppoe_password: event.target.value })}
            disabled
          />
        </div>
        <div className="form-group">
          <label>OLT</label>
          <input
            type="text"
            className="form-control"
            value={form.olt}
            onChange={(event) => setForm({ ...form, olt: event.target.value })}
            disabled
          />
        </div>
        <div className="form-group">
          <label>Framed Pool</label>
          <input
            type="text"
            className="form-control"
            value={form.framed_pool}
            onChange={(event) => setForm({ ...form, framed_pool: event.target.value })}
            disabled
          />
        </div>
        <div className="form-group">
          <label>VLAN</label>
          <input
            type="text"
            className="form-control"
            value={form.vlan}
            onChange={(event) => setForm({ ...form, vlan: event.target.value })}
            disabled
          />
        </div>

        <div className="form-group">
          <label>ODC</label>
          <input
            type="text"
            className="form-control"
            value={form.odc}
            onChange={(event) => setForm({ ...form, odc: event.target.value })}
          />
        </div>
        <div className="form-group">
          <label>ODP</label>
          <input
            type="text"
            className="form-control"
            value={form.odp}
            onChange={(event) => setForm({ ...form, odp: event.target.value })}
          />
        </div>
        <div className="form-group">
          <label>Latitude Survey</label>
          <input
            type="text"
            className="form-control"
            value={form.latitude}
            onChange={(event) => setForm({ ...form, latitude: event.target.value })}
          />
        </div>
        <div className="form-group">
          <label>Longitude Survey</label>
          <input
            type="text"
            className="form-control"
            value={form.longitude}
            onChange={(event) => setForm({ ...form, longitude: event.target.value })}
          />
        </div>
        <div className="form-group">
          <label>Splitter</label>
          <SplitterAutoCompleteForm
            value={form.partner_odp_splitter_id}
            position={{ latitude: form.latitude, longitude: form.longitude }}
            onChange={(value) => setForm({ ...form, partner_odp_splitter_id: value })}
          />
        </div>
        <div className="form-group">
          <label>Splitter Port</label>
          <input
            type="number"
            className="form-control"
            value={form.partner_odp_splitter_port}
            onChange={(event) =>
              setForm({ ...form, partner_odp_splitter_port: event.target.value })
            }
          />
        </div>
        <div className="d-flex justify-content-end">
          <button className="btn btn-primary" disabled={submitLoading}>
            {submitLoading ? "Mensetup modem ..." : "Simpan Setting Modem"}
          </button>
        </div>
      </form>
    </>
  );
}

function CheckProvisioningBtn({ id }) {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [processing, setProcessing] = useState(false);

  const refreshProvisioningStatus = async (id) => {
    setProcessing(true);
    try {
      let { data } = await checkProvisioningFromPartnerInfra(id);
      setSuccess(data.message);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        if (data.message) {
          setError(data.message);
        } else {
          setError("Something Error Happened");
        }
      } else {
        setError("Something Error Happened");
      }
    }
    setProcessing(false);
  };

  if (success)
    return (
      <div className="text-success">
        <i className="fa fa-check"></i> {success}
      </div>
    );

  return (
    <>
      {error ? (
        <div className="text-danger">
          <i className="fa fa-times"></i> {error}
        </div>
      ) : null}
      <button
        className="btn btn-sm btn-primary"
        disabled={processing}
        onClick={() => refreshProvisioningStatus(id)}
      >
        {processing ? "tunggu .." : "cek status"}
      </button>
    </>
  );
}

function SplitterAutoCompleteForm({ value, position, onChange }) {
  const [inputSearch, setInputSearch] = useState("");
  const [editMode, setEditMode] = useState(false);

  const [filter, setFilter] = useState({ q: "", latitude: "", longitude: "" });
  const { data: splitters, loading, resetList } = useSearchPartnerSplitterListHooks({ filter });

  const debouncedSearchTerm = useDebounce(inputSearch, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setFilter((prev) => ({
        ...prev,
        q: debouncedSearchTerm,
      }));
    }
  }, [debouncedSearchTerm]);

  return (
    <>
      {editMode ? (
        <div className="input-group mb-2">
          <input
            type="text"
            className="form-control"
            placeholder="cari splitter berdasar kode splitter ..."
            value={inputSearch}
            onChange={(event) => setInputSearch(event.target.value)}
          />
          <div className="input-group-append">
            <button
              className="btn btn-danger"
              type="button"
              onClick={() => {
                setEditMode(false);
              }}
            >
              <div className="fa fa-rotate-left"></div>
            </button>
          </div>
        </div>
      ) : (
        <div className="input-group mb-2">
          <input type="text" className="form-control" value={value} disabled={!editMode} />
          <div className="input-group-append">
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => {
                setInputSearch(value);
                setFilter({ ...filter, ...position });
                setEditMode(true);
              }}
            >
              <div className="fa fa-chevron-down"></div>
            </button>
          </div>
        </div>
      )}

      {editMode && (
        <div className="list-group">
          <div className="list-group-item bg-light font-weight-bold py-1">Pilih splitter:</div>
          {splitters.length === 0 && !loading && (
            <div className="list-group-item">Tidak ada splitter terdekat ...</div>
          )}
          {loading ? (
            <div className="list-group-item">Mencari ...</div>
          ) : (
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              {splitters.map((splitter, index) => (
                <button
                  key={index}
                  type="button"
                  className="list-group-item list-group-item-action"
                  onClick={() => {
                    onChange(splitter.partner_odp_splitter_id);
                    setInputSearch("");
                    setEditMode(false);
                    resetList();
                  }}
                >
                  {splitter.partner_odp_splitter_id} ({metersToKm(splitter.distance_meters)})
                </button>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
}

function metersToKm(meters) {
  if (meters >= 900) {
    const km = meters / 1000;
    return `${km.toFixed(2)} km`;
  } else {
    return `${meters} m`;
  }
}
